<template>
  <div>
    <vue-headful
      title="Разместить объявление о продаже"
      description="Разместить объявление о продаже"
    />
    <div class="section section-main-100 ">
      <v-container
        fluid
        class="base-content pt-5"
      >
        <v-row>
          <v-col
            cols="12"
            md="10"
            class="pa-8"
          >
            <h2 class="pt-2 pb-2">Разместить объявление о продаже.</h2>
            <div class="mt-10">
              <p>Размещение объявления о&nbsp;продаже квартиры на&nbsp;нашем сайте бесплатно для всех. Единственное
                условие для размещения объявления&nbsp;&mdash; информация об&nbsp;объекте недвижимости выставляемом на&nbsp;нашем
                сайте должна быть достоверна.</p>
              <p>Мы&nbsp;не&nbsp;навязываем подписание эксклюзивов, нам это не&nbsp;интересно.</p>
              <p>Разместив свое объявление о&nbsp;продаже на&nbsp;нашем сайте, либо продав квартиру через наш сайт&nbsp;&mdash;
                вы&nbsp;нам ничего не&nbsp;должны. Нам платят наши клиенты.</p>
              <p>На&nbsp;нашем сайте размещены только реально существующие объекты недвижимости (рекламных и&nbsp;&laquo;заманушных&raquo;
                объявлений у&nbsp;нас нет).<br/>
                Следовательно, ваша квартира, выставленная на&nbsp;нашем сайте, конкурирует с&nbsp;другими реальными
                квартирами, а&nbsp;не&nbsp;с&nbsp;красивыми картинками из&nbsp;интернета. Соответственно, скорость
                продажи квартир выставленных на&nbsp;нашем сайте (при прочих равных) значительно выше, чем скорость
                продажи квартир выставленных на&nbsp;рекламных площадках, где им&nbsp;приходится конкурировать с&nbsp;&laquo;заманухами&raquo;.
              </p>
              <p>Разместив свой объект недвижимости на&nbsp;нашем сайте вы&nbsp;экономите своё время и&nbsp;нервы, так
                как ваш номер телефона не&nbsp;виден различным
                <nobr>спам-рассылкам</nobr>
                , мошенникам, &laquo;туристам&raquo; (люди, которые ради скуки ходят по&nbsp;квартирам, чтоб посмотреть
                ремонты), тем кто просто чистит свои базы на&nbsp;предмет актуальности
                <nobr>и т. д.</nobr>
                <nobr>и т. п.</nobr>
                Все эти бессмысленные разговоры с&nbsp;ними мы&nbsp;берём на&nbsp;себя. А&nbsp;все бессмысленные показы&nbsp;&mdash;
                посмотреть чтоб было с&nbsp;чем сравнить, посмотреть чтоб подумать стоит&nbsp;ли через год покупать, и&nbsp;прочее
                и&nbsp;прочее&nbsp;&mdash; мы&nbsp;это всё отсекаем, так как нам неинтересно тратить ни&nbsp;своё ни&nbsp;ваше
                время.
              </p>
              <p>Наши покупатели&nbsp;&mdash; адекватные люди, которые понимают что рекламные площадки кишат
                несуществующими в&nbsp;природе квартирами с&nbsp;красивыми фотографиями и&nbsp;смешными ценами, и&nbsp;ценят
                своё время. Поэтому они подбирают себе варианты на&nbsp;нашей площадке, где нет всего этого мусора.
                Поэтому очень большой шанс, что выставив свою квартиру у&nbsp;нас, вы&nbsp;быстро и&nbsp;без нервов
                сумеете решить свой квартирный вопрос.</p>
              <p>Сделаем вместе рынок недвижимости прозрачнее, комфортнее и&nbsp;безопаснее.</p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ManualAdvert'
}
</script>

<style scoped>

</style>
